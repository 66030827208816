import React,{useState} from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import axios from 'axios';
import Prductslide1 from './Prductslide1';
import Profilesidebar from './Profilesidebar';




 
  
 

class Profile extends React.Component{


  constructor(props) {
    super(props);
    this.state = {
      api_url:localStorage.getItem('api_url'),
      user_id:localStorage.getItem('admin_id'),
        data:[],product:{},
        product_detail:[],
        prescription:'no',search:'',suc_cls:'',
        errors:  {},openModal : false,pic:'',fileName:'',email:'',email1:'',password:''
        
    };     

    // console.log(this.state.product_id)
    
  }
  product_detailapi(){
    

    axios({
      method: 'get',
      url: this.state.api_url+'/admingetProduct_frontend/'+this.state.product_id,               
      
    }).then(res => {
      const data = res.data;      
      this.setState({                         
              product: data.product,             
            });
    });
    // console.log(this.state.product)
  }
  

  componentDidMount() {    
    // this.product_detailapi(); 
}
  

      render(){
        return  <div className="box"> 
                   { <Header_home /> }       
                            
                                 <Profilesidebar />
                          <br></br>
                          <br></br>
                          <div className='sell_best_product'>
                          <h2 className="">Similar Products</h2>
                          <Prductslide1/>
                          <br></br>
                          <br></br>
                          <br></br>
                       </div>
                   { <Footer /> }
                </div>
      }
}

export default Profile;