import React, { useState, useCallback, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';

const libraries = ['places'];  // Load the places library

const MapComponent = ({ onLocationSelect }) => {
  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const autocompleteRef = useRef(null);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const location = place.geometry.location;
      const position = { lat: location.lat(), lng: location.lng() };
      setMarkerPosition(position);
      map.panTo(position);  // Pan the map to the new location

      if (onLocationSelect) {
        onLocationSelect(position);  // Pass the location to the parent component
      }
    }
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyCkRSn-ZXBqRN5Qm2cDL3Wz1hz2xftOft4" libraries={libraries}>
      <GoogleMap
        onLoad={onLoad}
        mapContainerStyle={{ width: '100%', height: '400px' }}
        center={markerPosition || { lat: -34.397, lng: 150.644 }}  // Default location
        zoom={10}
      >
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            type="text"
            placeholder="Search location"
            style={{ boxSizing: 'border-box', border: '1px solid transparent', width: '240px', height: '32px', padding: '0 12px', borderRadius: '3px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)', fontSize: '14px', outline: 'none', textOverflow: 'ellipses', position: 'absolute', left: '50%', top: '10px', transform: 'translateX(-50%)' }}
          />
        </Autocomplete>

        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
