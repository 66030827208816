import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Bannerslideshow.css';

const Bannerslideshow = () => {
  const [banners, setBanners] = useState([]);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    const fetchBanners = async () => {
      const api_url = localStorage.getItem('api_url');
      const response = await axios.get(api_url + '/getBannerContents');
      setBanners(response.data.banners);
    };

    fetchBanners();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBannerIndex((prevIndex) =>
        prevIndex === banners.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change banner every 3 seconds

    return () => clearInterval(interval);
  }, [banners.length]);

  return (
    <div className="banner-slideshow">
      {banners.map((banner, index) => (
        <div
          key={index}
          className={`banner-slide ${
            index === currentBannerIndex ? 'active' : ''
          }`}
          style={{ backgroundImage: `url(${banner.banner})` }}
        >
          <div className="banner-text">
            <h1>{banner.text}</h1>
            <p>{banner.subtext}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Bannerslideshow;
