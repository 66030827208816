import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "./Prductslide.css";
import Product_rating from './Product_rating';


const Prductslide2 = () => {
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [cards, setCards] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchRecentlyViewed = () => {
      const recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
      setRecentlyViewed(recentlyViewed);
    };

    fetchRecentlyViewed();
  }, []);

  const handleDivClick = (id) => {
    localStorage.setItem("product_id", id);
    navigate("/Detail_page", { replace: true });
  };

  const onNextClick = () => {
    const newIndex = startIndex + 5;
    if (newIndex < cards.length) {
      setStartIndex(newIndex);
    }
  };

  const onPrevClick = () => {
    const newIndex = startIndex - 5;
    if (newIndex >= 0) {
      setStartIndex(newIndex);
    }
  };

  // Calculate the container width
  const visibleCards = recentlyViewed.slice(startIndex, startIndex + 5);
  const containerWidth = visibleCards.length === 5 ? '100%' : '75%';

  return (
    <div className="slideshows">
      <div className="card-container" style={{ width: containerWidth }}>
        {recentlyViewed.map((product) => (
          <div key={product.id} className="card" onClick={() => handleDivClick(product.id)}>
            <img className='med_img' src={product.product_image} alt={product.product_name} />
            <div className="medicine-details">
              <p className="pro_ids">{product.product_name}</p>
              <div className="rating">
                <Product_rating />
              </div>
              <p className="pro_pz">{product.price} Rs</p>
            </div>
          </div>
        ))}
      </div>
      <div className="controls">
            <button className="bu_n" onClick={onPrevClick} disabled={startIndex === 0}>
              Prev
            </button>
            <button className="bu_n" onClick={onNextClick} disabled={startIndex + 5 >= cards.length}>
              Next
            </button>
          </div>
    </div>
  );
};

export default Prductslide2;
