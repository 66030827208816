import React from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Menu from './Menu';
import axios from 'axios';
import Prductslide from './Prductslide';
import Detail_tab from './Detail_tab';
import FavoriteCheckbox from './FavoriteCheckbox';
import Detail_rating from './Detail_rating';
import OrderCard from './Ordercard';
import Savecart from './Savecart';
import Addresschange from './Addresschange';




 
  
 

class Addcart extends React.Component{


  constructor(props) {
    super(props);
    this.state = {
      api_url:localStorage.getItem('api_url'),
      user_id:localStorage.getItem('admin_id'),
        data:[],
        carts:[],
        prescription:'no',search:'',suc_cls:'',
        errors:  {},openModal : false,pic:'',fileName:'',email:'',email1:'',password:''
        
    };     
  }

  add_to_cart_api(){

        console.log(this.state.user_id);
        axios({
        method: 'get',
        url: this.state.api_url+'/getusercarts/'+this.state.user_id,               
        }).then(res => {
        const data = res.data;      
        this.setState({                         
                carts: data.cartItems,             
              });
        });
  }
 
  componentDidMount() {  
    
    this.add_to_cart_api();
}
  

      render(){
        return <div className="box"> {
          <Header_home /> } <br></br>
          <br></br>
         
          <Addresschange/>

          <div className='social_container'>
            <div className='rating_detail_page'>
              <div class="rating"></div>
            </div>
          </div>
          <div class="container">
  
              
                <OrderCard/>
               


               

          </div>

            <div className='price_amount2'>
                <Savecart/>
            </div>


          <br></br>
          <br></br>
          <div className='sell_best_product'>
            <h2 className="">Recently Viewed Products</h2>
            <Prductslide />
            <br></br>
            <br></br>
            <br></br>
          </div> {
          <Footer /> }
        </div>
      }
}

export default Addcart;