import React, { Component } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Prductslide1 from './Prductslide1';
import { withRouter } from "./withRouter";
import Myorderdetails from './Myorderdetails';

class Myorders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      user_id: localStorage.getItem('admin_id'),
      orderStatus: {
        order_placed: false,
        shipped: false,
        delivered: false,
        returned: false
      },
      orderTime: {
        last_30_days: false,
        year_2023: false,
        year_2022: false,
        year_2021: false,
        year_2020: false,
        others: false
      }
    };
  }

  handleOrderStatusChange = (e) => {
    const { name, checked } = e.target;
    this.setState(prevState => ({
      orderStatus: {
        order_placed: name === 'order_placed' ? checked : false,
        shipped: name === 'shipped' ? checked : false,
        delivered: name === 'delivered' ? checked : false,
        returned: name === 'returned' ? checked : false
      }
    }));
  };

  handleOrderTimeChange = (e) => {
    const { name, checked } = e.target;
    this.setState(prevState => ({
      orderTime: {
        ...prevState.orderTime,
        [name]: checked
      }
    }));
  };

  render() {
    return (
      <div className="box">
        <Header_home />
        <div className='myorders'>
          <div className='fil_order'>
            <p className='my_or'><b>Filters</b></p>
            <p></p>
            <hr></hr>
            <p className='my_or'><b>ORDER STATUS</b></p>
            <label className='or_status'><input type="checkbox" name="order_placed" onChange={this.handleOrderStatusChange} checked={this.state.orderStatus.order_placed} /> Order placed</label>
            <label className='or_status'><input type="checkbox" name="shipped" onChange={this.handleOrderStatusChange} checked={this.state.orderStatus.shipped} /> Shipped</label>
            <label className='or_status'><input type="checkbox" name="delivered" onChange={this.handleOrderStatusChange} checked={this.state.orderStatus.delivered} /> Delivered</label>
            <label className='or_status'><input type="checkbox" name="returned" onChange={this.handleOrderStatusChange} checked={this.state.orderStatus.returned} /> Returned</label>
            <p></p>
            <hr></hr>
            <p className='my_or'><b>ORDER TIME</b></p>
            <label className='or_status'><input type="checkbox" name="last_30_days" onChange={this.handleOrderTimeChange} checked={this.state.orderTime.last_30_days} /> Last 30 days</label>
            <label className='or_status'><input type="checkbox" name="year_2024" onChange={this.handleOrderTimeChange} checked={this.state.orderTime.year_2024} /> 2024</label>
            <label className='or_status'><input type="checkbox" name="year_2023" onChange={this.handleOrderTimeChange} checked={this.state.orderTime.year_2023} /> 2023</label>
            <label className='or_status'><input type="checkbox" name="year_2022" onChange={this.handleOrderTimeChange} checked={this.state.orderTime.year_2022} /> 2022</label>
            <label className='or_status'><input type="checkbox" name="year_2021" onChange={this.handleOrderTimeChange} checked={this.state.orderTime.year_2021} /> 2021</label>
            <label className='or_status'><input type="checkbox" name="others" onChange={this.handleOrderTimeChange} checked={this.state.orderTime.others} /> Others</label>
          </div>
          <div className='my_ord'>
            <Myorderdetails
              orderStatus={this.state.orderStatus}
              orderTime={this.state.orderTime}
            />
          </div>
        </div>
        <br></br>
        <br></br>
        <div className='sell_best_product'>
          <h2 className="">Similar Products</h2>
          <Prductslide1 />
          <br></br>
          <br></br>
          <br></br>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Myorders);
