import React from 'react';
import {withRouter} from "./withRouter";
import './style.css';
import Footer from './Footer';
import axios from 'axios';
import Header_signup from './Header_signup';
import validator from 'validator';


class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      prescription: 'no',
      search: '',
      suc_cls: '',
      errors: {},
      openModal: false,
      pic: '',
      fileName: '',
      email: '',
      email1: '',
      password: '',
      conpassword: '',
      firstname: '',
      lastname: '',
      mobile: '',
      passwordError: '',
      confirmPasswordError: ''
    };

    this.onChange_email = this.onChange_email.bind(this);
    this.onChange_email1 = this.onChange_email1.bind(this);
    this.onChange_password = this.onChange_password.bind(this);
    this.onChange_firstname = this.onChange_firstname.bind(this);
    this.onChange_lastname = this.onChange_lastname.bind(this);
    this.onChange_mobile = this.onChange_mobile.bind(this);
    this.onChange_conpassword = this.onChange_conpassword.bind(this);
    this.loginfun = this.loginfun.bind(this);
  }

  onChange_email(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChange_email1(e) {
    this.setState({
      email1: e.target.value
    });
  }

  onChange_password(e) {
    this.setState({
      password: e.target.value
    });
  }

  onChange_conpassword(e) {
    this.setState({
      conpassword: e.target.value
    });
  }

  onChange_firstname(e) {
    this.setState({
      firstname: e.target.value
    });
  }

  onChange_lastname(e) {
    this.setState({
      lastname: e.target.value
    });
  }

  onChange_mobile(e) {
    this.setState({
      mobile: e.target.value
    });
  }

  validatePassword() {
    const { firstname,lastname,mobile,email1,password, conpassword } = this.state;
    let passwordError = '';
    let confirmPasswordError = '';
    let firstnameError ='';
    let lastnameError ='';
    let mobileError ='';
    let emailError ='';

    const nameRegex = /^[a-zA-Z]+$/; // Regex to allow only letters

    if (!firstname) {
        firstnameError = 'First Name is required';
      } else if (!nameRegex.test(firstname)) {
        firstnameError = 'First Name should contain only letters';
      }
    
      if (!lastname) {
        lastnameError = 'Last Name is required';
      } else if (!nameRegex.test(lastname)) {
        lastnameError = 'Last Name should contain only letters';
      }

      if (!mobile) {
        mobileError = 'Mobile Number is required';
    } else if (!validator.isMobilePhone(mobile, 'any')) {
        mobileError = 'Enter a valid mobile number';
    }

    if (!email1) {
        emailError = 'Email Id is required';
    } else if (!validator.isEmail(email1)) {
        emailError = 'Enter a valid email address';
    }

    if (!password) {
      passwordError = 'Password is required';
    } else if (password.length < 6) {
      passwordError = 'Password must be at least 6 characters';
    }

    if (!conpassword) {
      confirmPasswordError = 'Confirm Password is required';
    } else if (conpassword !== password) {
      confirmPasswordError = 'Passwords do not match';
    }

    this.setState({firstnameError,lastnameError,mobileError,emailError, passwordError, confirmPasswordError });

    return !(firstnameError || lastnameError || mobileError || emailError || passwordError || confirmPasswordError);
  }

  loginfun(e) {
    e.preventDefault();

    const isValid = this.validatePassword();

    if (isValid) {
      // Proceed with your login logic
      // Example axios request
      axios({
        method: 'post',
        url: this.state.api_url + '/adminsignup_front',
        data: { 'firstname':this.state.firstname,
                'lastname':this.state.lastname,
                'phone_number':this.state.mobile,
                'email': this.state.email1, 
                'password': this.state.password }
      })
        .then(res => {
          if (res.data.status == "false") {
            let errors = {};
            const message = res.data.message;
            errors["message"] = message;
            this.setState({
              errors: errors,
              suc_cls: 'errorMsg floatingAlerts1'
            });
            setTimeout(() => {
              this.setState({
                suc_cls: 'errorMsghide'
              });
            }, 3000);

          } else {

            //   localStorage.setItem("admin_id", res.data.admin_id[0].id)
            alert('Signup successfully completed');
            this.props.navigate('/Login');

          }
        });
    }
  }

  render() {
    return (
      <div className="">
        {<Header_signup />}
        <div class="row log_fun">
          <div className=" col-md-6 logo_img" >
            <img className="group_img" alt="Group" src={require('./img/Group.png')} />
          </div>

          <div className="col-md-6 logo_img1">
            {/* <img className='pro_logo' alt="Logo" src={require('./img/logo.png')} /> */}

            <p className="login_now">Signup now</p>
            <div className="welcome_ba">Hi, Welcome back </div>

            <div className={`${this.state.suc_cls} error`}>{this.state.errors.message}</div>

            <br></br>
            <div className="overlap-wrapper">
              <div className="overlap">
                <label className='label_email'>First Name</label>
                <br></br>
                <input required className="input-BG" type="text" id="firstname" autoComplete='off' placeholder="   Enter your First Name" value={this.state.firstname} onChange={this.onChange_firstname} />
                {this.state.firstnameError && <div className="error">{this.state.firstnameError}</div>}
              </div>
            </div>
            <br></br>
            <div className="overlap-wrapper">
              <div className="overlap">
                <label className='label_email'>Last Name</label>
                <br></br>
                <input required className="input-BG" type="text" id="lastname" autoComplete='off' placeholder="   Enter your Last Name" value={this.state.lastname} onChange={this.onChange_lastname} />
                {this.state.lastnameError && <div className="error">{this.state.lastnameError}</div>}
              </div>
            </div>
            <br></br>
            <div className="overlap-wrapper">
              <div className="overlap">
                <label className='label_email'>Mobile Number</label>
                <br></br>
                <input required className="input-BG" type="text" id="mobile" autoComplete='off' placeholder="   Enter your Mobile Number" value={this.state.mobile} onChange={this.onChange_mobile} />
                {this.state.mobileError && <div className="error">{this.state.mobileError}</div>}
              </div>
            </div>
            <br></br>
            <div className="overlap-wrapper">
              <div className="overlap">
                <label className='label_email'>Email</label>
                <br></br>
                <input required className="input-BG" type="email" id="emai" autoComplete='off' placeholder="   Enter your email Id" value={this.state.email1} onChange={this.onChange_email1} />
                {this.state.emailError && <div className="error">{this.state.emailError}</div>}
              </div>
            </div>
            <br></br>
            <div className="overlap-wrapper">
              <div className="overlap">
                <label className='label_password'>Password</label>
                <br></br>
                <input className="input-BG1" id="password" autoComplete='off' value={this.state.password} onChange={this.onChange_password} type="password" maxLength={15} minLength={6} placeholder="   Password" required />
                {this.state.passwordError && <div className="error">{this.state.passwordError}</div>}
              </div>
            </div>
            <br></br>
            <div className="overlap-wrapper">
              <div className="overlap">
                <label className='label_password'>Confirm Password</label>
                <br></br>
                <input className="input-BG1" id="password1" autoComplete='off' value={this.state.conpassword} onChange={this.onChange_conpassword} type="password" maxLength={15} minLength={6} placeholder="  Confirm Password" required />
                {this.state.confirmPasswordError && <div className="error">{this.state.confirmPasswordError}</div>}
              </div>
            </div>
            <br></br>
            <button onClick={this.loginfun} className="continue" style={{ border: "none" }}>   Sign Up     </button>
          </div>
        </div>
        {<Footer />}
      </div>
    );
  }

}

export default withRouter(Signup);
