import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "./Prductslide.css";
import Product_rating from './Product_rating';

const Prductslide = () => {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();

  const handleDivClick = (id) => {
    localStorage.setItem("product_id", id);
    navigate("/Detail_page", { replace: true });
  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const response = await axios.post(api_url + '/admingetProducts_front');
        setCards(response.data.products);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);

  const [startIndex, setStartIndex] = useState(0);

  const onNextClick = () => {
    const newIndex = startIndex + 5;
    if (newIndex < cards.length) {
      setStartIndex(newIndex);
    }
  };

  const onPrevClick = () => {
    const newIndex = startIndex - 5;
    if (newIndex >= 0) {
      setStartIndex(newIndex);
    }
  };

  // Calculate the container width
  const visibleCards = cards.slice(startIndex, startIndex + 5);
  const containerWidth = visibleCards.length === 5 ? '100%' : '75%';

  return (
    <div className="slideshows">
      <div className="card-container" style={{ width: containerWidth }}>
        {visibleCards.map((card) => (
          <div key={card.id} className="card">
            {card.product_image ? (
              <img
                className='med_img'
                src={card.product_image}
                alt={card.product_name}
                onClick={() => handleDivClick(card.id)}
              />
            ) : (
              <div className="placeholder-image" onClick={() => handleDivClick(card.id)}>
                No Image Available
              </div>
            )}
            <div className="medicine-details">
              <p className="pro_ids">{card.product_name}</p>
              <div className="rating">
                <Product_rating />
              </div>
              <div className="price-details">
                {card.discountAmount && card.discountAmount !== card.price ? (
                  <>
                    <p className="pro_pz original-price"><s>{card.price} Rs</s></p>
                    <p className="pro_pz discounted-price">{card.discountAmount} Rs</p>
                  </>
                ) : (
                  <p className="pro_pz">{card.price} Rs</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <br />
      {visibleCards.length > 0 && (
        <div className="controls">
          <button className="bu_n" onClick={onPrevClick} disabled={startIndex === 0}>
            Prev
          </button>
          <button className="bu_n" onClick={onNextClick} disabled={startIndex + 5 >= cards.length}>
            Next
          </button>
        </div>
      )}
     
    </div>
  );
};

export default Prductslide;
